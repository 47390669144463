import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { Container } from "../global"

import { translate } from "../../i18n/translate"

const ContactUs = ({ t, i18n }) => (
    <ContactWrapper>
        <Container>
          <Subtitle>{t('contactUs.contactUs')}</Subtitle>
            <br />
            <br />
            <Flex>
                  <ImageWrapper>
                    <h1>{t('contactUs.relax')}</h1>
                    <br />
                    <StyledImage fluid={EventsTemplate().file.childImageSharp.fluid} />
                    <br />
                  </ImageWrapper>
                  <ContactTextGroup>
                    <form name="contact" 
                    method="post"
                    action="#" 
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    >
                      <input type="hidden" name="bot-field" />
                      <input type="hidden" name="form-name" value="contact" />
                        <label>
                            {t('contactUs.name')}
                            <ContactInput type="text" name="name" id="name" />
                        </label>
                        <br />
                        <br />
                        <label>
                            {t('contactUs.email')}
                            <ContactInput type="email" name="email" id="email" />
                        </label>
                        <br />
                        <br />
                        <label>
                            {t('contactUs.subject')}
                            <ContactInput type="text" name="subject" id="subject" />
                        </label>
                        <br />
                        <br />
                        <label>
                            {t('contactUs.message')}
                            <ContactTextArea name="message" id="message" rows="5" />
                        </label>
                        <br />
                        <br />
                        <ContactButton type="submit">{t('contactUs.send')}</ContactButton>
                    </form>
                  </ContactTextGroup>
            </Flex>
        </Container>
    </ContactWrapper>
)

export default translate(ContactUs)


const ContactWrapper = styled.section`
  background-color: #f8f8f8;
  padding: 60px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const ContactButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 500px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Flex = styled.div`
    display: grid;
    grid-area: main;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
    }
`

const ContactTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const ContactInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const ContactTextArea = styled.textarea`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 16px;
  width: 100%;
  text-align: left;
  height: 180px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const ImageWrapper = styled.div`
  margin: 0;

  > div {
    width: 115%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-right: 30px;
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  h3 {
    margin-right: 30px;
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }

  justify-self: left;
  align-self: left;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: left;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
const EventsTemplate = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "couch" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return data
}