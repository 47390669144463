import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { translate } from "../../i18n/translate"

const Features = ({t, i18n }) => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>{t('features.features')}</Subtitle>
      <SectionTitle>{t('features.automate')}</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>{t('features.notifications-title')}</FeatureTitle>
          <FeatureText>
            {t('features.notifications-paragraph')}
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>{t('features.security-title')}</FeatureTitle>
          <FeatureText>
            {t('features.security-paragraph')}
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>{t('features.automation-title')}</FeatureTitle>
          <FeatureText>
            {t('features.automation-paragraph')}
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>{t('features.performance-title')}</FeatureTitle>
          <FeatureText>
            {t('features.performance-paragraph')}
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default translate(Features)

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
