import React from "react"
import styled from "styled-components"
import { Container } from "../global"
import { translate } from "../../i18n/translate"


const Footer = ({t, i18n }) => (
  <FooterWrapper id="footer">
    <Subtitle>{t('footer.weIndies')}</Subtitle>
    <Paragraph>{t('footer.madeBy')}</Paragraph>
    <FooterColumnContainer>
      <FooterColumn>
        <span>{t('footer.features')}</span>
        <ul>
          <li>{t('footer.automation')}</li>
          <li>{t('footer.rewards')}</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>{t('footer.resources')}</span>
        <ul>
          <li>{t('footer.compare')}</li>
          <li>{t('footer.blog')}</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>{t('footer.company')}</span>
        <ul>
          <li>{t('footer.aboutUs')}</li>
          <li>{t('footer.careers')}</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>{t('footer.social')}</span>
        <ul>
          <li>{t('footer.linkedIn')}</li>
          <li>{t('footer.instagram')}</li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <Logo>{t('footer.motto')}</Logo>
    </BrandContainer>
  </FooterWrapper>
)

export default translate(Footer)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`
const Subtitle = styled.h5`
  padding: 0px;
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: center;
`

const Paragraph = styled.h6`
  margin: 0% 20% 20% 20%;
  padding: 20px;
  font-size: 16px;
  color: black;
  letter-spacing: 0px;
  margin-bottom: 50px;
  text-align: center;
`